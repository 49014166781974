import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import {
  AllProductFamilyProps,
  AllSectionProps,
  SectionProps
} from '../components/types';
import { normalizePath } from '../utils';

export const sectionQuery = graphql`
  query SectionQuery($id: String!) {
    ...AllSectionFragment
    ...AllProductFamilyFragment
    contentfulSection(id: { eq: $id }) {
      ...SectionFragment
    }
  }
`;

const getBreadcrumbPath = (props: SectionProps & AllSectionProps) => {
  const home = props.allContentfulSection.edges[0].node;
  const active = props.contentfulSection;
  const path: { slug: string; title: string }[] = [home];

  if (active.parentPage) {
    path.push(active.parentPage);
  }

  path.push(active);

  return path.map(({ slug, title }) => ({
    slug,
    title
  }));
};

const SectionTemplate = (props: {
  data: AllSectionProps & AllProductFamilyProps & SectionProps;
  location: { pathname: string };
}) => {
  const {
    location: { pathname },
    data: {
      allContentfulSection,
      allContentfulProductFamily,
      contentfulSection
    }
  } = props;
  const currentPath = normalizePath(pathname);
  return (
    <Layout
      title={contentfulSection.pageHeaderTitle || contentfulSection.title}
      currentSectionPath={currentPath}
      currentLocation={currentPath}
      menuData={{ allContentfulSection }}
      breadcrumbPath={getBreadcrumbPath({
        allContentfulSection,
        contentfulSection
      })}
    >
      <Section
        contentfulSection={contentfulSection}
        allContentfulProductFamily={allContentfulProductFamily}
      />
    </Layout>
  );
};

export default SectionTemplate;
